import { ref } from 'vue'
import Program from '@/assets/ts/interfaces/Program'

/**
 * Represents the member's current Program.
 */
const program = ref<any>( {} )

const useStoreProgram = () => {
  return {
    program
  }
}

export default useStoreProgram
