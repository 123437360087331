import {
  differenceInDays,
  format
} from 'date-fns'
import {
  removeSharp,
  trendingDownSharp,
  trendingUpSharp
} from 'ionicons/icons'
import {
  Ref,
  computed,
  ref
} from 'vue'
import Http from '@/assets/ts/classes/Http'
import type TrendWeightEntry from '@/assets/ts/interfaces/TrendWeightEntry'
import Modal from '@/assets/ts/useModal'
import round from '@/assets/ts/utilities/round'
import unitsAbbr from '@/assets/ts/utilities/unitsAbbr'

const modalAddWeightValue = new Modal()
const modalEditWeightValue = new Modal()
const modalDeleteWeightValue = new Modal()
const modalAddMeasurementsValue = new Modal()
const modalEditMeasurementValue = new Modal()
const modalDeleteMeasurementValue = new Modal()
const newWeighIn = ref<number|null>( null )
const progressLoading = ref( false )
const weightDate = ref( format( new Date(), 'yyyy-MM-dd' ) )
const styledDateFormat = 'do LLL'
const progressTooltipIsOpen = ref( false )
const measurementsTooltipIsOpen = ref( false )
// const dateFormatted = computed( () => format( weightDate.value, 'Y-MM-dd' ) )

const timeOptions = [
  {
    name: '7',
    abbr: '7D',
    query: 'last-7-days',
    time: 'Last 7 days'
  },
  {
    name: '30',
    abbr: '1M',
    query: 'last-month',
    time: 'Last month'
  },
  {
    name: '90',
    abbr: '3M',
    query: 'last-3-months',
    time: 'Last 3 months'
  },
  {
    name: '180',
    abbr: '6M',
    query: 'last-6-months',
    time: 'Last 6 months'
  },
  {
    name: '365',
    abbr: '1Y',
    query: 'last-year',
    time: 'Last year'
  },
  {
    name: '0',
    abbr: 'All',
    query: 'all-time',
    time: 'All time'
  }
]

interface WeightEntry {
  date: string;
  weight: number;
}

interface ProgressData {

  /**
   * The user's first weight entry chronologically.
   */
  startingWeight: number;

  /**
   * The user's unit for weight.
   */
  units: 'pounds' | 'kilograms';

  /**
   * The user's first weight entry with a formatted date.
   */
  firstWeightEntryDateFormatted: string | null;

  /**
   * The weight entries for the given query.
   */
  convertedEntries: WeightEntry[];

  /**
   * The weight entries for the given query.
   */
  entries: WeightEntry[];

}

const progressData = ref<ProgressData>( {
  startingWeight: 0,
  units: 'pounds',
  firstWeightEntryDateFormatted: null,
  convertedEntries: [],
  entries: [],
} )

const smoothingFactor = 0.1
const trendWeights = ref<TrendWeightEntry[]>( [] )
const filteredTrendWeights = ref<TrendWeightEntry[]>( [] )
const firstFilteredTrendWeight = computed( () => filteredTrendWeights.value ? filteredTrendWeights.value[0] : 0 )
const displayedTrendWeights = computed( () => [ ...trendWeights.value ].reverse().filter( trendWeight => differenceInDays( new Date(), trendWeight.date ) <= 30 ) )
let trendWeightsArray: TrendWeightEntry[] = []
let scaleWeight: number|null = 0
let yesterdaysTrendWeight = 0
let prepareToInterpolate = false

const hasWeightEntries = computed( () => progressData.value.entries.length > 0 )
const hasTrendWeightEntries = computed( () => filteredTrendWeights.value.length > 0 )
const weightEntries = computed( () => progressData.value.entries )

const durationLabels: Record<number, Record<string,string>> = {
  0: {
    first: 'First ever logged weight',
    change: 'Weight change all time'
  },
  7: {
    first: 'Trend weight 7D ago',
    change: 'Weight change since 7D ago'
  },
  30: {
    first: 'Trend weight 1M ago',
    change: 'Weight change since 1M ago'
  },
  90: {
    first: 'Trend weight 3M ago',
    change: 'Weight change since 3M ago'
  },
  180: {
    first: 'Trend weight 6M ago',
    change: 'Weight change since 6M ago'
  },
  365: {
    first: 'Trend weight 1Y ago',
    change: 'Weight change since 1Y ago'
  }
}

const getPrettyTimeFrame = ( timeframe: string ) => {
  const option = timeOptions.find( option => option.query === timeframe )
  return option ? option.time : ''
}

const firstDurationLabel = ref( durationLabels[0].first )
const changeDurationLabel = ref( durationLabels[0].change )

/**
 * The user's first weight logged chronologically.
 */
const firstEverLoggedWeight = computed( () => hasWeightEntries.value ? progressData.value.entries[0].weight : null )
const startingWeight = computed( () => hasTrendWeightEntries.value ? round( filteredTrendWeights.value[0].trendWeight, 1 ) : null )
const firstFilteredWeightEntry = computed( () => hasTrendWeightEntries.value ? filteredTrendWeights.value[0] : null )
const firstFilteredWeightDateFormatted = computed( () => hasTrendWeightEntries.value ? format( filteredTrendWeights.value[0].date, 'do LLL Y' ) : null )
const mostRecentWeightEntryIndex = computed( () => hasWeightEntries.value ? weightEntries.value.length - 1: null )
const mostRecentWeight = computed( () => mostRecentWeightEntryIndex.value ? weightEntries.value[mostRecentWeightEntryIndex.value].weight : null )
const mostRecentTrendWeight = computed( () => hasTrendWeightEntries.value ? round( filteredTrendWeights.value[filteredTrendWeights.value.length - 1].trendWeight, 1 ) : null )

const currentTrendWeight = computed( () => trendWeights.value.length ? round( trendWeights.value[trendWeights.value.length - 1].trendWeight, 1 ) : null )
const totalProgressChange = computed( () => firstEverLoggedWeight.value && currentTrendWeight.value ? round( currentTrendWeight.value - firstEverLoggedWeight.value, 1 ) : null )
const progressChange = computed( () => startingWeight.value && currentTrendWeight.value ? round( currentTrendWeight.value - startingWeight.value, 1 ) : null )
const progressChangeSign = computed( () => totalProgressChange.value && totalProgressChange.value >= 0 ? trendingUpSharp : totalProgressChange.value !== null ? trendingDownSharp : undefined )
const weightChangeAllTime = computed( () => startingWeight.value && mostRecentTrendWeight.value ? round( mostRecentTrendWeight.value - startingWeight.value, 1 ).toFixed( 1 ) : 0 )
const unitsAbbreviated = computed( () => unitsAbbr( progressData.value.units ) )
const unitsAbbreviatedSingular = computed( () => unitsAbbr( progressData.value.units, true ) )
const unitsSingular = computed( () => progressData.value.units.slice( 0, - 1 ) )

const useTrendWeight = () => {

  const getTrendIcon = ( number: number ) => number > 0 ? trendingUpSharp : number < 0 ? trendingDownSharp : removeSharp
  const currentDuration = ref( '0' )

  const handleInput = ( $event: KeyboardEvent ) => {
    const target = $event.target as HTMLInputElement
    const hasDecimal = ( target.value.includes( '.' ) || target.value.includes( ',' ) ) && ( $event.code === 'Period' || $event.code === 'Comma' )
    if ( hasDecimal ) $event.preventDefault()
    return true
  }

  const getNumberOfDaysSince = ( start: Date ) => {
    const date1 = new Date( start )
    const date2 = new Date()

    // One day in milliseconds.
    const oneDay = 1000 * 60 * 60 * 24

    // Calculating the time difference between two dates.
    const diffInTime = date2.getTime() - date1.getTime()

    // Calculating the no. of days between two dates.
    const diffInDays = Math.round( diffInTime / oneDay )

    return diffInDays
  }

  const createDateLabels = ( entries: TrendWeightEntry[], timeZone: string ) =>
    entries.map( entry =>
      format( new Date( entry.date.toLocaleString( 'en-US', { timeZone } ) ), styledDateFormat ) )

  const getTrendWeights = async ( weights: any[] ) => {
    trendWeightsArray = []
    if ( ! weights || ! weights.length ) return []
    const firstWeighInDate = new Date( weights[0].date )
    // console.log( 'firstWeighInDate', firstWeighInDate )
    const loopDuration = getNumberOfDaysSince( firstWeighInDate )
    for ( let i = 0; i <= loopDuration; i ++ ) {
      let date = null
      let weight = null
      if ( i === 0 ) {
        date = firstWeighInDate
        weight = weights[0].weight
      } else {
        date = new Date(
          firstWeighInDate.getFullYear(),
          firstWeighInDate.getMonth(),
          firstWeighInDate.getDate() + i
        )
      }
      const trendWeight: TrendWeightEntry = {
        scaleWeight: weight,
        scaleWeightInterpolated: false,
        trendWeight: weight,
        date: date,
        interpolate: false,
        weeklyChange: {
          dayNumber: 0,
          trendWeightDayValue: 0,
          daySquared: 0
        },
      }
      if ( trendWeight.date.getTime() < new Date().getTime() ) trendWeightsArray.push( trendWeight )
    }

    // Attach the scale weights to the existing entries.
    weights.forEach( weight => {
      const date = new Date( weight.date )
      const matched = trendWeightsArray.find( trendWeight => trendWeight.date.getTime() === date.getTime() )
      if ( matched ) matched.scaleWeight = weight.weight
    } )

    trendWeightsArray.forEach( ( trendWeight, index ) => {
      // Do we have a weight from today?
      if ( index === 0 ) {
        scaleWeight = trendWeightsArray[0].scaleWeight
        yesterdaysTrendWeight = trendWeightsArray[0].trendWeight
      } else {
        const date = trendWeight.date
        const matched = weights.find( weight => new Date( weight.date ).getTime() === date.getTime() )

        /**
         * Do we have a logged weight for this day?
         */
        if ( matched && trendWeight.scaleWeight ) {
          // console.log( 'logged weight', trendWeight.date, trendWeight.scaleWeight )
          scaleWeight = trendWeight.scaleWeight
          yesterdaysTrendWeight = trendWeightsArray[index - 1].trendWeight
          trendWeight.trendWeight = Number( ( scaleWeight * smoothingFactor + yesterdaysTrendWeight * ( 1 - smoothingFactor ) ).toFixed( 1 ) )
          if ( prepareToInterpolate ) {
            const firstToInterpolate = trendWeightsArray.findIndex( trendWeight => trendWeight.interpolate )
            // console.log( 'firstToInterpolate', index, firstToInterpolate )
            const firstToInterpolateObject = trendWeightsArray[firstToInterpolate - 1]
            // console.log( 'firstToInterpolateObject', firstToInterpolateObject )
            const trendWeightsArrayReversed = [ ...trendWeightsArray ].reverse()
            const lastToInterpolate = trendWeightsArrayReversed.findIndex( trendWeight => trendWeight.interpolate )
            const firstToReference = trendWeightsArray[firstToInterpolate - 1]
            const lastToReference = trendWeightsArrayReversed[lastToInterpolate - 1]
            if ( lastToReference && lastToReference.scaleWeight && firstToReference.scaleWeight ) {
              const lastToReferenceIndexInTrendWeightsArray = trendWeightsArray.findIndex( trendWeight => trendWeight.date === lastToReference.date )
              // console.log( 'lastToReference', lastToReference )
              // console.log( 'lastToReferenceIndexInTrendWeightsArray', lastToReferenceIndexInTrendWeightsArray )
              // console.log( `indexes between ${firstToInterpolate}-${lastToInterpolate} (in reversed) should be false` )
              // console.log( `current one set to false: ${index - 1}`, `indexes between ${firstToInterpolate}-${lastToReferenceIndexInTrendWeightsArray - 1} (in regular) should be false` )
              const weightDifference = round( lastToReference.scaleWeight - firstToReference.scaleWeight, 1 )
              const entriesToInterpolate = trendWeightsArray.filter( trendWeight => trendWeight.interpolate )
              const interpolatedDays = entriesToInterpolate.length
              const offset = 1
              const interpolation = weightDifference / ( interpolatedDays + offset )
              const datesToInterpolate = entriesToInterpolate.map( entry => entry.date )
              datesToInterpolate.forEach( ( date, index ) => {
                const newDate = trendWeightsArray.find( trendWeight => trendWeight.date.getTime() === date.getTime() )
                if ( newDate ) {
                  const weightToInterpolate = trendWeightsArray[firstToInterpolate - 1 + index].scaleWeight
                  if ( index === 0 && firstToInterpolateObject.scaleWeight ) {
                    newDate.scaleWeight = firstToInterpolateObject.scaleWeight + interpolation
                    newDate.scaleWeightInterpolated = true
                  } else if ( weightToInterpolate ) {
                    newDate.scaleWeight = weightToInterpolate + interpolation
                    newDate.scaleWeightInterpolated = true
                  }
                }
              } )
              prepareToInterpolate = false
              for ( let i = firstToInterpolate; i <= lastToReferenceIndexInTrendWeightsArray - 1; i ++ ) {
                trendWeightsArray[i].interpolate = false
              }
            }
            // trendWeightsArray[index - 1].interpolate = false
          }
        } else {
          prepareToInterpolate = true
          yesterdaysTrendWeight = trendWeightsArray[index - 1].trendWeight
          // console.log( 'yesterdaysTrendWeight', yesterdaysTrendWeight )
          if ( scaleWeight ) trendWeight.trendWeight = Number( ( scaleWeight * smoothingFactor + yesterdaysTrendWeight * ( 1 - smoothingFactor ) ).toFixed( 1 ) )
          trendWeight.interpolate = true
          yesterdaysTrendWeight = trendWeightsArray[index - 1].trendWeight
        }
      }
    } )

    // Loop through trend weights and compare to weights.
    // trendWeightsArray.forEach( ( trendWeight, index ) => {
    //   if ( index === 0 && trendWeightsArray[0].scaleWeight ) {
    //     scaleWeight = trendWeightsArray[0].scaleWeight
    //     yesterdaysTrendWeight = trendWeightsArray[0].trendWeight
    //   } else {
    //     const date = trendWeight.date
    //     const matched = weights.find( weight => new Date( weight.date ).getTime() === date.getTime() )
    //     if ( matched && scaleWeight && trendWeight.scaleWeight ) {
    //       scaleWeight = trendWeight.scaleWeight
    //       yesterdaysTrendWeight = trendWeightsArray[index - 1].trendWeight
    //       trendWeight.trendWeight = scaleWeight * smoothingFactor + yesterdaysTrendWeight * ( 1 - smoothingFactor )
    //       if ( prepareToInterpolate ) {
    //         const firstToInterpolate = trendWeightsArray.findIndex( trendWeight => trendWeight.interpolate )
    //         const firstToInterpolateObject = trendWeightsArray[firstToInterpolate - 1]
    //         const trendWeightsArrayReversed = [...trendWeightsArray].reverse()
    //         const lastToInterpolate = trendWeightsArrayReversed.findIndex( trendWeight => trendWeight.interpolate )
    //         const firstToReference = trendWeightsArray[firstToInterpolate - 1]
    //         const lastToReference = trendWeightsArrayReversed[lastToInterpolate - 1]
    //         const lastToReferenceIndexInTrendWeightsArray = trendWeightsArray.findIndex( trendWeight => trendWeight.date === lastToReference.date )
    //         console.log( 'firstToInterpolate', index, firstToInterpolate )
    //         console.log( 'firstToInterpolateObject', firstToInterpolateObject )
    //         console.log( 'lastToReference', lastToReference )
    //         console.log( 'lastToReferenceIndexInTrendWeightsArray', lastToReferenceIndexInTrendWeightsArray )
    //         console.log( `indexes between ${firstToInterpolate}-${lastToInterpolate} (in reversed) should be false` )
    //         console.log( `current one set to false: ${index - 1}`, `indexes between ${firstToInterpolate}-${lastToReferenceIndexInTrendWeightsArray - 1} (in regular) should be false` )
    //         if ( lastToReference && lastToReference.scaleWeight && firstToReference.scaleWeight ) {
    //           const weightDifference = round( lastToReference.scaleWeight - firstToReference.scaleWeight, 1 )
    //           const entriesToInterpolate = trendWeightsArray.filter( trendWeight => trendWeight.interpolate )
    //           const interpolatedDays = entriesToInterpolate.length
    //           const offset = 1
    //           const interpolation = round( weightDifference / ( interpolatedDays + offset ), 3 )
    //           const datesToInterpolate = entriesToInterpolate.map( entry => entry.date )
    //           datesToInterpolate.forEach( ( date, index ) => {
    //             const newDate = trendWeightsArray.find( trendWeight => trendWeight.date.getTime() === date.getTime() )
    //             if ( newDate ) {
    //               const weightToInterpolate = trendWeightsArray[firstToInterpolate - 1 + index].scaleWeight
    //               if ( index === 0 && firstToInterpolateObject.scaleWeight ) {
    //                 newDate.scaleWeight = firstToInterpolateObject.scaleWeight + interpolation
    //                 newDate.scaleWeightInterpolated = true
    //               } else if ( weightToInterpolate ) {
    //                 newDate.scaleWeight = weightToInterpolate + interpolation
    //                 newDate.scaleWeightInterpolated = true
    //               }
    //             }
    //           } )
    //         }
    //         prepareToInterpolate = false
    //         for ( let i = firstToInterpolate; i <= lastToReferenceIndexInTrendWeightsArray - 1; i++ ) {
    //           console.log( 'i', i )
    //           // trendWeightsArray[i].interpolate = false
    //         }
    //       }
    //     } else {
    //       prepareToInterpolate = true
    //       yesterdaysTrendWeight = trendWeightsArray[index - 1].trendWeight
    //       trendWeight.trendWeight = scaleWeight * smoothingFactor + yesterdaysTrendWeight * ( 1 - smoothingFactor )
    //       trendWeight.interpolate = true
    //       yesterdaysTrendWeight = trendWeightsArray[index - 1].trendWeight
    //     }
    //   }
    // } )

    trendWeightsArray.forEach( ( trendWeight, index ) => {
      if ( index === 0 && trendWeightsArray[0].scaleWeight ) {
        scaleWeight = trendWeightsArray[0].scaleWeight
        yesterdaysTrendWeight = trendWeightsArray[0].trendWeight
      } else {
        if ( trendWeight.scaleWeight !== null ) {
          scaleWeight = trendWeight.scaleWeight
          yesterdaysTrendWeight = trendWeightsArray[index - 1].trendWeight
          trendWeight.trendWeight = scaleWeight * smoothingFactor + yesterdaysTrendWeight * ( 1 - smoothingFactor )
        }
      }
    } )

    return trendWeightsArray
  }

  const deleteWeight = async ( memberId: number, weight: number, entry: TrendWeightEntry ) =>
    await Http.delete( `/member/${memberId}/weight`, {
      date: format( entry.date, 'Y-MM-dd' )
    } )

  const patchEditWeight = async ( memberId: number, weight: number, entry: TrendWeightEntry|string ) =>
    await Http.post( `/member/${memberId}/weight`, {
      weight: String( weight ),
      date: typeof entry === 'string' ? entry : format( entry.date, 'Y-MM-dd' )
    } )

  /**
   * Get sessions with optional parameters.
   */
  const getWeight = async ( memberId: number, params: object ) =>
    Http.get( `/member/${memberId}/weight`, { params } )

  /**
   * Get measurement entries for a user.
   */
  const getMeasurements = async ( memberId: number, params: object|null = null ) =>
    Http.get( `/member/${memberId}/measurements`, { params } )

  return {
    filteredTrendWeights,
    getTrendWeights,
    trendWeights,
    weightDate,
    firstFilteredWeightEntry,
    firstFilteredWeightDateFormatted,
    // dateFormatted,
    currentTrendWeight,
    modalAddWeightValue,
    modalEditWeightValue,
    modalDeleteWeightValue,
    modalAddMeasurementsValue,
    modalEditMeasurementValue,
    modalDeleteMeasurementValue,
    newWeighIn,
    // postAddWeight,
    deleteWeight,
    patchEditWeight,
    getWeight,
    progressData,
    progressChange,
    progressChangeSign,
    weightChangeAllTime,
    hasWeightEntries,
    weightEntries,
    startingWeight,
    mostRecentWeightEntryIndex,
    mostRecentWeight,
    progressLoading,
    unitsAbbreviated,
    unitsAbbreviatedSingular,
    unitsSingular,
    timeOptions,
    displayedTrendWeights,
    getTrendIcon,
    durationLabels,
    firstDurationLabel,
    changeDurationLabel,
    firstEverLoggedWeight,
    totalProgressChange,
    styledDateFormat,
    getMeasurements,
    handleInput,
    getPrettyTimeFrame,
    progressTooltipIsOpen,
    measurementsTooltipIsOpen,
    createDateLabels,
    currentDuration,
    firstFilteredTrendWeight
  }

}

export default useTrendWeight
