import Http from '@/assets/ts/classes/Http'

/**
 * Get a program by ID.
 *
 * @param id The program ID.
 */
const getProgram = async ( id: number ) => Http.get( `/program/${id}` )

export default getProgram
