import { ref } from 'vue'
import Http from '@/assets/ts/classes/Http'
import type Session from '@/assets/ts/interfaces/Session'
import lbsToKgs from '@/assets/ts/lbstoKgs'
import kgsToLbs from '@/assets/ts/update-goal/kgsToLbs'
import round from '@/assets/ts/utilities/round'

/**
 * Represents an array all previous sessions completed for the current workout.
 */
const previousSessions = ref<Session[]|null>( null )
const previousSession = ref<Session|null>( null )
const previousLoggedSet = ref<any>( null )

/**
 * Create a ref to display the units from the previous session.
 */
const previousTrainingSessionUnitsDisplay = ref<string|null|undefined>( null )

/**
 * Composable for logic related to previous sessions.
 */
const usePreviousSession = () => {

  /**
   * Get the previous session.
   */
  const getPreviousSession = (
    memberId: number,
    workoutId: number,
    programId: number
  ) => Http.get( `/member/${memberId}/previous-session`, { params: { workoutId, programId } } )

  /**
   * Convert the value from the previous session.
   */
  const getPreviousSessionConverted = ( previousSessionWeight: number ) => {
    if ( previousTrainingSessionUnitsDisplay.value === previousSession.value?.trainingUnits ) return previousSessionWeight
    return previousTrainingSessionUnitsDisplay.value === 'pounds' ? round( kgsToLbs( previousSessionWeight ), 1 ) : round( lbsToKgs( previousSessionWeight ), 1 )
  }

  /**
   * Toggle the units between pounds and kilograms.
   */
  const convertPreviousSessionUnits = () => previousTrainingSessionUnitsDisplay.value = previousTrainingSessionUnitsDisplay.value === 'pounds' ? 'kilograms' : 'pounds'

  return {
    previousSessions,
    previousSession,
    getPreviousSession,
    previousLoggedSet,
    previousTrainingSessionUnitsDisplay,
    getPreviousSessionConverted,
    convertPreviousSessionUnits
  }

}

export default usePreviousSession
