import { ref } from 'vue'
import Http from '@/assets/ts/classes/Http'

const lastSession = ref<any>( null )

const useLastSession = () => {

  const getLastSession = async ( memberId: number, programId: number ) => Http.get( `/member/sessions?limit=1&program_id=${programId}&detail=true&created_by=${memberId}` )

  return {
    getLastSession,
    lastSession
  }
}

export default useLastSession
