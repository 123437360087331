import {
  computed,
  ref
} from 'vue'
import experienceLevelAdvanced from '@/assets/images/icons/exp-level-advanced.svg'
import experienceLevelBeginner from '@/assets/images/icons/exp-level-beginner.svg'
import experienceLevelIntermediate from '@/assets/images/icons/exp-level-intermediate.svg'
import activityLevelSedentary from '@/assets/images/icons/goal/activity-level-sedentary.svg'
import maleBodyFatOne from '@/assets/images/icons/goal/body-fat/1.svg'
import maleBodyFatTwo from '@/assets/images/icons/goal/body-fat/2.svg'
import maleBodyFatThree from '@/assets/images/icons/goal/body-fat/3.svg'
import maleBodyFatFour from '@/assets/images/icons/goal/body-fat/4.svg'
import maleBodyFatFive from '@/assets/images/icons/goal/body-fat/5.svg'
import maleBodyFatSix from '@/assets/images/icons/goal/body-fat/6.svg'
import maleBodyFatSeven from '@/assets/images/icons/goal/body-fat/7.svg'
import maleBodyFatEight from '@/assets/images/icons/goal/body-fat/8.svg'
import femaleBodyFatOne from '@/assets/images/icons/goal/body-fat/female/1.svg'
import femaleBodyFatTwo from '@/assets/images/icons/goal/body-fat/female/2.svg'
import femaleBodyFatThree from '@/assets/images/icons/goal/body-fat/female/3.svg'
import femaleBodyFatFour from '@/assets/images/icons/goal/body-fat/female/4.svg'
import femaleBodyFatFive from '@/assets/images/icons/goal/body-fat/female/5.svg'
import femaleBodyFatSix from '@/assets/images/icons/goal/body-fat/female/6.svg'
import femaleBodyFatSeven from '@/assets/images/icons/goal/body-fat/female/7.svg'
import femaleBodyFatEight from '@/assets/images/icons/goal/body-fat/female/8.svg'
import dietPreferenceBalanced from '@/assets/images/icons/goal/diet-preference-balanced.svg'
import dietPreferenceHighCarb from '@/assets/images/icons/goal/diet-preference-high-carb.svg'
import dietPreferenceHighFat from '@/assets/images/icons/goal/diet-preference-high-fat.svg'
import dietPreferenceKeto from '@/assets/images/icons/goal/diet-preference-keto.svg'
import activityLevelActive from '@/assets/images/icons/goal/icon-active.svg'
import iconBuild from '@/assets/images/icons/goal/icon-build.svg'
import calendarZero from '@/assets/images/icons/goal/icon-calendar-0.svg'
import calendarThree from '@/assets/images/icons/goal/icon-calendar-3.svg'
import calendarFour from '@/assets/images/icons/goal/icon-calendar-4.svg'
import calendarFive from '@/assets/images/icons/goal/icon-calendar-5.svg'
import calendarSixPlus from '@/assets/images/icons/goal/icon-calendar-6-plus.svg'
import calendarSix from '@/assets/images/icons/goal/icon-calendar-6.svg'
import activityLevelHighlyActive from '@/assets/images/icons/goal/icon-highly-active.svg'
import iconLean from '@/assets/images/icons/goal/icon-lean.svg'
import activityLevelLightlyActive from '@/assets/images/icons/goal/icon-lightly-active.svg'
import iconMaintain from '@/assets/images/icons/goal/icon-maintain.svg'
import activityLevelVeryActive from '@/assets/images/icons/goal/icon-very-active.svg'
import Http from '@/assets/ts/classes/Http'
import GoalDietPreference from '@/assets/ts/types/GoalDietPreference'
import RateOfLossSpeed from '@/assets/ts/types/RateOfLossSpeed'
import calculateRMR from '@/assets/ts/update-goal/calculateRMR'
import useTrendWeight from '@/assets/ts/useTrendWeight'
import getDietPreferencePretty from '@/assets/ts/utilities/getDietPreferencePretty'

const { currentTrendWeight } = useTrendWeight()

interface Goal {
  ID: number;
  achieveOption: string;
  daysIn: number;
  dateCompleted?: string;
  dietPreference: string;
  dietPreferencePretty: string;
  experienceLevel: string;
  modalDismissed: boolean;
  startDate: string;
  startingWeight: number;
  goalWeight: number;
  rateOfChange: number;
  totalMovementFactor: number;
  calories: number;
  protein: number;
  carbs: number;
  fat: number;
}

interface GoalData {
  age: number;
  rmr: number;
  gender: string;
  weight: Record<string, any>;
  height: Record<string, any>;
  goals: Goal[];
}

const femaleBodyFatPercentages = [
  {
    label: '<18%',
    value: 18,
    icon: femaleBodyFatOne,
  },
  {
    label: '19-23%',
    value: 21,
    icon: femaleBodyFatTwo,
  },
  {
    label: '24-27%',
    value: 25.5,
    icon: femaleBodyFatThree,
  },
  {
    label: '28-31%',
    value: 29.5,
    icon: femaleBodyFatFour,
  },
  {
    label: '32-35%',
    value: 33.5,
    icon: femaleBodyFatFive,
  },
  {
    label: '36-40%',
    value: 38,
    icon: femaleBodyFatSix,
  },
  {
    label: '41-45%',
    value: 43,
    icon: femaleBodyFatSeven,
  },
  {
    label: '>46%',
    value: 46,
    icon: femaleBodyFatEight,
  },
]

const maleBodyFatPercentages = [
  {
    label: '<9%',
    value: 9,
    icon: maleBodyFatOne
  },
  {
    label: '10-13%',
    value: 11.5,
    icon: maleBodyFatTwo
  },
  {
    label: '14-17%',
    value: 15.5,
    icon: maleBodyFatThree
  },
  {
    label: '18-21%',
    value: 19.5,
    icon: maleBodyFatFour
  },
  {
    label: '22-25%',
    value: 23.5,
    icon: maleBodyFatFive
  },
  {
    label: '26-30%',
    value: 28,
    icon: maleBodyFatSix
  },
  {
    label: '31-35%',
    value: 33,
    icon: maleBodyFatSeven
  },
  {
    label: '>36%',
    value: 36,
    icon: maleBodyFatEight
  }
]

const goalOptions = ref<Record<any,any>>( {
  bodyFatPercentages: null,
  dietPreferenceOptions: [
    {
      label: 'Balanced',
      value: 'balanced',
      icon: dietPreferenceBalanced,
      description: 'Carbs and fats will be distributed evenly across your calories',
      descriptionMessage: 'You prefer a balanced diet, so carbs and fats have been distributed evenly across the remaining calories.'
    },
    {
      label: 'High carb',
      value: 'high-carb',
      icon: dietPreferenceHighCarb,
      description: 'Fats will be kept lower with a preference for carbs',
      descriptionMessage: 'You prefer a higher carb diet, so the remaining calories have been biased toward carbs.'
    },
    {
      label: 'High fat',
      value: 'high-fat',
      icon: dietPreferenceHighFat,
      description: 'Carbs will be kept lower with a preference for fats',
      descriptionMessage: 'You prefer a higher fat diet, so the remaining calories have been biased toward fats.'
    },
    {
      label: 'Keto',
      value: 'keto',
      icon: dietPreferenceKeto,
      description: 'Carbs will be greatly restricted with the majority of your calories coming from fats',
      descriptionMessage: 'You prefer a ketogenic diet, so carbs have been heavily restricted, with the remaining calories coming primarily from fats.'
    },
  ],
  experienceLevelOptions: [
    {
      label: 'Beginner',
      value: 'beginner',
      icon: experienceLevelBeginner,
    },
    {
      label: 'Intermediate',
      value: 'intermediate',
      icon: experienceLevelIntermediate,
    },
    {
      label: 'Advanced',
      value: 'advanced',
      icon: experienceLevelAdvanced
    }
  ],
  achieveOptions: [
    {
      label: 'Lean',
      value: 'lean',
      description: 'Lose body fat and get lean',
      descriptionMessage: 'You want to lose body fat and get lean while maximising muscle retention.',
      icon: iconLean,
    },
    {
      label: 'Build',
      value: 'build',
      description: 'Build muscle and increase strength',
      descriptionMessage: 'You want to focus on building muscle and strength.',
      icon: iconBuild,
    },
    {
      label: 'Maintain',
      value: 'maintain',
      description: 'Maintain your current body weight',
      descriptionMessage: 'You want to maintain your current weight.',
      icon: iconMaintain,
    },
  ],
  activityLevelOptions: [
    {
      label: 'Sedentary',
      value: 1.2,
      description: '<5000 steps per day',
      icon: activityLevelSedentary,
    },
    {
      label: 'Lightly active',
      value: 1.3,
      description: '5-6k steps per day',
      icon: activityLevelLightlyActive,
    },
    {
      label: 'Active',
      value: 1.4,
      description: '8-10k steps per day',
      icon: activityLevelActive,
    },
    {
      label: 'Very active',
      value: 1.45,
      description: '10-15k steps per day',
      icon: activityLevelVeryActive,
    },
    {
      label: 'Highly active',
      value: 1.5,
      description: '>15k steps per day',
      icon: activityLevelHighlyActive,
    },
  ],
  proteinIntakeOptions: {
    'pounds': {
      min: 0.8,
      max: 1.2,
      default: function() { return ( this.min + this.max ) / 2 },
      step: 0.1
    },
    'kilograms': {
      min: 1.8,
      max: 2.6,
      default: function() { return ( this.min + this.max ) / 2 },
      step: 0.1
    }
  },
  strengthTrainingFrequencyOptions: [
    {
      label: '3 times per week',
      value: 3,
      icon: calendarThree,
    },
    {
      label: '4 times per week',
      value: 4,
      icon: calendarFour,
    },
    {
      label: '5 times per week',
      value: 5,
      icon: calendarFive,
    },
    {
      label: '6 times per week',
      value: 6,
      icon: calendarSix,
    }
  ],
  otherExerciseFrequencyOptions: [
    {
      label: 'None',
      value: 0,
      icon: calendarZero,
    },
    {
      label: '1-3 times per week',
      value: 2,
      icon: calendarThree,
    },
    {
      label: '4-6 times per week',
      value: 5,
      icon: calendarFour,
    },
    {
      label: '>6 times per week',
      value: 6,
      icon: calendarSixPlus,
    }
  ]
}
)

const goalData = ref<GoalData>( {
  age: 0,
  rmr: 0,
  gender: '',
  weight: {},
  height: {},
  goals: []
} )

const rateOfGainRecommendations: Record<string, number[]> = {
  beginner: [ 1, 1.5 ],
  intermediate: [ 0.5, 1 ],
  advanced: [ 0.3, 0.5 ]
}

const rateOfLossColors: Record<RateOfLossSpeed, string> = {
  'Slow': 'blue-500',
  'Recommended': 'green-bright',
  'Aggressive': 'orange-700',
  'Very aggressive': 'red-700',
  '': 'white'
}

const hasPreviousGoals = computed( () => goalData.value.goals.length > 1 )
const completedGoals = computed( () => goalData.value.goals.filter( goal => goal.dateCompleted ) )
const hasCompletedGoals = computed( () => completedGoals.value.length > 0 )

const latestGoal = computed( () => goalData.value?.goals
  ? goalData.value.goals[ goalData.value.goals.length - 1 ]
  : null
)

const latestGoalRowIndex = computed( () => goalData.value.goals
  ? goalData.value.goals.length
  : null )

const currentGoal = ref( {

  /**
   * The target weight of the goal if one exists.
   */
  get targetWeight() {
    return latestGoal.value ? latestGoal.value.goalWeight : null
  },

  /**
   * Returns the difference between the target weight and current weight,
   * or null if one or both do not exist.
   */
  get toGoal() {
    return this.targetWeight && currentTrendWeight.value
    && latestGoal.value && latestGoal.value.achieveOption !== 'maintain'
      ?
      this.targetWeight - currentTrendWeight.value
      :
      null
  },

  get goalAchieved() {
    return latestGoal.value && this.toGoal
    && ( latestGoal.value.achieveOption === 'build' && this.toGoal <= 0
    || latestGoal.value.achieveOption === 'lean' && this.toGoal >= 0 )
  },

  /**
   * If our goal is maintain, we are looking for target deviation.
   */
  get targetDeviation() {
    return this.targetWeight && currentTrendWeight.value
    && latestGoal.value && latestGoal.value.achieveOption === 'maintain'
      ?
      currentTrendWeight.value - this.targetWeight
      :
      0
  },

  get percentOfGoal() {
    return this.targetWeight && currentTrendWeight.value && latestGoal.value
      ?
      latestGoal.value.achieveOption === 'build'
        ? currentTrendWeight.value / this.targetWeight * 100
        : this.targetWeight / currentTrendWeight.value * 100
      :
      null
  },

  get goalTargetWeightChange() {
    return this.targetWeight && currentTrendWeight.value && latestGoal.value
      ?
      latestGoal.value.startingWeight - latestGoal.value.goalWeight
      :
      null
  },

  get goalTargetWeightChangeRounded() {
    return this.goalTargetWeightChange?.toFixed( 0 )
  },

  get goalCurrentWeightChange() {
    return this.targetWeight && currentTrendWeight.value && latestGoal.value
      ?
      Math.abs( currentTrendWeight.value - latestGoal.value.startingWeight )
      :
      null
  },

  get goalCurrentWeightChangeRounded() {
    return this.goalCurrentWeightChange?.toFixed( 0 )
  },

  get currentGoalProgressPercentage() {
    return this.goalCurrentWeightChange && this.goalTargetWeightChange
      ?
      Math.abs( this.goalCurrentWeightChange / this.goalTargetWeightChange ) * 100
      :
      null
  },

  get percentOfTarget() {
    return this.targetWeight && currentTrendWeight.value && latestGoal.value
      ?
      latestGoal.value.achieveOption === 'build'
        ? latestGoal.value.startingWeight / this.targetWeight * 100
        : this.targetWeight / latestGoal.value.startingWeight * 100
      :
      null
  }
} )

const getTargetDeviationVerbiage = ( targetDeviation: number ) => {
  if ( targetDeviation < 0 ) {
    return 'Below target'
  } else if ( targetDeviation === 0 ) {
    return 'On target'
  } else if ( targetDeviation > 0 ) {
    return 'Above target'
  }
  return ''
}

/**
 * Boolean representing the initial fetch for the goal data.
 */
const initialFetch = ref( false )

const useNewGoal = () => {

  const updateGoal = ( weight: number, age: number|null = null ) => {
    if ( goalData.value && age ) {
      goalData.value.rmr = calculateRMR(
        age,
        goalData.value.gender,
        weight,
        goalData.value.height.totalInches,
        goalData.value.weight.units
      )
      console.log( 'goalData', goalData.value )
    }
  }

  const postReadNewGoalModal = ( memberId: number ) =>
    Http.post( `/member/${memberId}/new-goal`, { readNewGoalModal: true } )

  const postReadGoalAchievedModal = ( memberId: number ) =>
    Http.post( `/member/${memberId}/new-goal`, { goalCompleteModalRead: latestGoalRowIndex.value } )

  /**
   * Get the previous session.
   */
  const getGoalData = ( memberId: number ) => Http.get( `/member/${memberId}/new-goal` )

  const postNewGoal = ( memberId: number, data: any ) =>
    Http.post( `/member/${memberId}/new-goal`, data )

  /**
   * Get the previous session.
   */
  const patchGoal = ( memberId: number, data: any ) =>
    Http.patch( `/member/${memberId}/new-goal`, data )

  return {
    initialFetch,
    goalOptions,
    goalData,
    getGoalData,
    updateGoal,
    currentGoal,
    postNewGoal,
    patchGoal,
    latestGoal,
    hasPreviousGoals,
    hasCompletedGoals,
    completedGoals,
    maleBodyFatPercentages,
    femaleBodyFatPercentages,
    rateOfGainRecommendations,
    rateOfLossColors,
    getTargetDeviationVerbiage,
    postReadNewGoalModal,
    postReadGoalAchievedModal
  }

}

export default useNewGoal
