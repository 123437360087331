import { defineStore } from 'pinia'
import Favourite from '@/assets/ts/interfaces/Favourite'
import Profile from '@/assets/ts/interfaces/Profile'

interface State {
  favourites: Favourite[];
  profile: Profile;
}

export const useProfileStore = defineStore( 'profile', {
  state: (): State => ( {
    profile: {
      received: false,
      subscription: {
        currency: '',
        customer: null,
        stripeId: null,
        active: null,
        planName: '',
      },
      location: {
        ip: null,
        geolocation: null,
        currency: {
          name: null,
          symbol: null,
        }
      },
      name: {
        firstName: '',
        lastName: '',
        nickname: '',
      },
      ID: 0,
      contactInfo: {
        emailAddress: '',
      },
      dateRegistered: '',
      profileImage: '',
      memberType: null,
      memberSince: null,
      settings: {
        dateFormat: '',
        macroViewMode: '',
        acceptedManualMacrosWarning: false,
        units: {
          weight: '',
          measurements: '',
        },
      },
      appSettings: {
        theme: null,
        sounds: null,
        notifications: null,
      },
      currentProgram: {
        thumb: null,
        name: null,
      },
      age: 0,
      dateOfBirth: '',
      height: {
        feet: 0,
        inches: 0,
        totalInches: 0,
        centimeters: 0,
      },
      gender: '',
      weight: 0,
      bodyFatPercentage: 0,
      activityLevel: '',
      mainGoal: '',
      experienceLevel: '',
      trainingFrequency: null,
      equipmentAvailable: null,
      dietPreference: null,
      timezone: '',
      macros: {
        calories: 0,
        protein: 0,
        fat: 0,
        carbs: 0,
      }
    },
    favourites: [],
  } )
} )