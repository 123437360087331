import hasRequirements from '@/assets/ts/update-goal/hasRequirements'
import insToCms from '@/assets/ts/update-goal/insToCms'

/**
 * Calculate resting metabolic rate.
 *
 * @param {Number} age         The user's age.
 * @param {string} gender      The user's gender.
 * @param {Number} weight      The user's weight, in either pounds or kilograms.
 * @param {Number} totalInches The user's total inches, regardless of unit preference.
 * @param {string} units       The user's units for weight measurements.
 * @returns Number rmr         The user's resting metabolic rate after calculations.
 */
const calculateRMR = ( age: number, gender: string, weight: number, totalInches: number, units: string ) => {

  console.log( 'weight', weight )
  // Reset RMR so we can recalculate.
  let rmr: number|null = 0

  // Check if we have the required values for the formula, bail and reset otherwise.
  if ( ! hasRequirements(
    [
      age,
      gender,
      weight,
      totalInches,
      units
    ]
  ) ) {
    rmr = null
    return 0
  }

  const formulas: Record<string,number> = {
    pounds: ( 4.536 * Number( weight ) ) + ( 15.88 * totalInches ) - ( 5 * Number( age ) ),
    kilograms: ( 10 * Number( weight ) ) + ( 6.25 * insToCms( Number( totalInches ) ) ) - ( 5 * Number( age ) )
  }

  rmr = Math.round( ( gender === 'male' ) ? formulas[units] + 5 : formulas[units] - 161 )

  return rmr

}

export default calculateRMR
