import * as Sentry from '@sentry/vue'
import { Vue } from '@sentry/vue/types/types'
import env from '@/env'
import router from '@/router'

/**
 * Wrapper function to initialize Sentry.
 *
 * @param app The Vue application instance.
 */
const setupSentry = ( app: Vue ) => {
  if ( env === 'production' ) {
    Sentry.init( {
      app,
      logErrors: true,
      dsn: 'https://eaaf34866afa46379c0ffbf6aa41d122@o1095701.ingest.sentry.io/6115527',
      tracePropagationTargets: [ 'localhost', 'app.physiqonomics.com', /^\// ],
      integrations: [
        Sentry.browserTracingIntegration( { router } ),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 0.2,
    } )
  }
}

/**
 * Add metadata to the Sentry request.
 */
const configureSentryScope = ( userId: string, userEmail: string, appVersion: string ) => {
  const scope = Sentry.getCurrentScope()
  scope.setUser( { id: userId, email: userEmail } )
  scope.setContext( 'app', { version: appVersion } )
}

export default setupSentry
export { configureSentryScope }
