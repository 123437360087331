/**
 * Gets the abbreviation for a unit.
 *
 * @param units The full string
 * @returns The abbreviated string.
 */
const unitsAbbr = ( units: string, singular = false ): string => {
  switch ( units ) {
  case 'kilograms':
    return singular ? 'kg' : 'kg'
  case 'pounds':
    return singular ? 'lb' : 'lbs'
  case 'centimeters':
    return singular ? 'cm' : 'cms'
  case 'inches':
    return singular ? 'in' : 'ins'
  default:
    return units
  }
}

export default unitsAbbr
