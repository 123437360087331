import {
  computed,
  ref
} from 'vue'

const macros = ref<Record<string,string>>( {} )

/**
 * Composable for managing macros.
 */
const useMacros = () => {

  /**
   * Checks if any macros are equal to null.
   *
   * @returns boolean True if any macros are not equal to null.
   */
  const hasMacros = computed( () => Object.values( macros.value ).some( macro => macro !== null ) )

  return {
    macros,
    hasMacros
  }
}

export default useMacros
