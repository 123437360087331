import { reactive } from 'vue'

const toast = reactive( {
  message: 'Your settings have been saved.',
  duration: 3000,
  isOpen: false,
  cssClass: 'bg-green-400'
} )

export default toast
