const ENV: Record<string, Record<string, string>> = {
  local: {
    rootURL: 'https://physiqo.local',
    clientID: '8YUeQynVf9rCOoVeg7SYlFzJPeXSdssjngxLVKRp',
  },
  development: {
    rootURL: 'https://physiqodev.wpengine.com',
    clientID: 'SnTfXstz8QGMIWslcLbwnBC23w0RPeElheoBL44M',
  },
  staging: {
    rootURL: 'https://physiqonomics.com',
    clientID: 'cUTdECqBHgFKMeoB9TnYmPofntREqQEdR2cvCTuz'
  },
  production: {
    rootURL: 'https://physiqonomics.com',
    clientID: 'cUTdECqBHgFKMeoB9TnYmPofntREqQEdR2cvCTuz'
  }
}

export default ENV
