<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script setup lang="ts">
import {
  IonApp,
  IonRouterOutlet
} from '@ionic/vue'
import { useStore } from 'vuex'
import pkg from '../package.json'
import getProfile from '@/assets/ts/getProfile'
import getProgram from '@/assets/ts/programs/getProgram'
import getCurrentSessionNumber from '@/assets/ts/sessions/getCurrentSessionNumber'
import useCurrentSet from '@/assets/ts/sessions/useCurrentSet'
import useLastSession from '@/assets/ts/sessions/useLastSession'
import useTodaysWorkout from '@/assets/ts/sessions/useTodaysWorkout'
import useFavourites from '@/assets/ts/useFavourites'
import useMacros from '@/assets/ts/useMacros'
import useNewGoal from '@/assets/ts/useNewGoal'
import usePreviousSession from '@/assets/ts/usePreviousSession'
import useWorkoutWeek from '@/assets/ts/useWorkoutWeek'
import useUserTimezone from '@/assets/ts/utilities/timezones/useUserTimezone'
import { configureSentryScope } from '@/plugins/sentry'
import { useProfileStore } from '@/store/profile'
import useStoreFavourites from '@/store/useStoreFavourites'
import useStoreProgram from '@/store/useStoreProgram'

( async () => {

  const store = useStore()
  const { macros } = useMacros()
  const { todaysWorkout } = useTodaysWorkout( store )
  const { getWorkoutsThisWeek, workoutsThisWeek, getCompletedSessions } = useWorkoutWeek()
  const { lastSession, getLastSession } = useLastSession()
  const { program } = useStoreProgram()
  const { checkUserTimezone } = useUserTimezone()
  const { session } = useCurrentSet()
  const { previousSessions, previousSession, getPreviousSession } = usePreviousSession()
  const { goalData, getGoalData } = useNewGoal()
  const $store = useProfileStore()
  const $favouritesStore = useStoreFavourites()
  const { getFavourites } = useFavourites()

  store.state.loading = true

  if ( window.location.pathname === '/login' ) return false

  const profile = await getProfile()

  if ( profile && store ) {
    store.state.profile = profile
    configureSentryScope( store.state.profile.ID, store.state.profile.contactInfo.emailAddress, pkg.version )
    const [ favourites, goal ] = await Promise.all( [
      getFavourites( store.state.profile.ID ),
      getGoalData( store.state.profile.ID )
    ] )
    $favouritesStore.favourites = favourites
    $store.$patch( profile )
    macros.value = store.state.profile.macros
    goalData.value = goal
    if ( ! store.state.profile.currentProgram.id ) {
      store.state.loading = false
      return false
    }
    const response = await getProgram( store.state.profile.currentProgram.id )
    if ( response ) {

      await checkUserTimezone()

      program.value = response

      // Get the last session.
      lastSession.value = await getLastSession( store.state.profile.ID, store.state.profile.currentProgram.id )

      workoutsThisWeek.value.workoutWeek = await getCompletedSessions( store.state.profile.ID )
      workoutsThisWeek.value.completedWorkouts = workoutsThisWeek.value.workoutWeek.sessions.completedSessions

      workoutsThisWeek.value.remainingWorkouts = await getWorkoutsThisWeek(
        workoutsThisWeek.value.completedWorkouts,
        store.state.profile.currentProgram.workoutWeek,
        program.value,
        todaysWorkout,
        store.state.profile.ID
      )

      // If we don't have a last session, use the first workout.
      if ( ! lastSession.value.length || lastSession.value[0].programID !== store.state.profile.currentProgram.id ) {
        workoutsThisWeek.value.completed = getCompletedSessions( store.state.profile.ID )
        return false
      }

      // If we do, use the first in the array returned.
      lastSession.value = lastSession.value[0]

      if ( ! lastSession.value.completed ) {
        store.state.session.ID = lastSession.value.ID
        session.value.inProgress = true
        session.value.ID = lastSession.value.ID
        todaysWorkout.value = program.value.workouts.find( ( workout: any ) => workout.ID === lastSession.value.workout.ID )
        workoutsThisWeek.value.remainingWorkouts = await getWorkoutsThisWeek(
          workoutsThisWeek.value.completedWorkouts,
          store.state.profile.currentProgram.workoutWeek,
          program.value,
          todaysWorkout
        )
        session.value.sets = lastSession.value.sets
        let selectedExercise = null
        if ( session.value.sets.length ) {
          session.value.sets.forEach( ( set: any ) => {
            todaysWorkout.value.setGroups.forEach( ( setGroup: any ) => {
              selectedExercise = setGroup.exercises.find( ( exercise: any ) => exercise.ID === set.exercise.ID )
              if ( ! selectedExercise ) setGroup.exercises.forEach( ( exercise: any ) => {
                if ( exercise.alternateExercises ) {
                  const alternateFound = exercise.alternateExercises.find( ( alternateExercise: any ) => alternateExercise.ID === set.exercise.ID )
                  if ( alternateFound ) {
                    selectedExercise = exercise
                    exercise.chosenExercise = set.exercise.ID
                  }
                }
              } )
              if ( selectedExercise ) {
                if ( ! selectedExercise.completedSets ) setGroup.exercises.forEach( ( exercise: any ) => exercise.completedSets = [] )
                selectedExercise.completedSets.push( set )
              }
            } )
          } )
        }
        previousSessions.value = await getPreviousSession(
          store.state.profile.ID,
          todaysWorkout.value.ID,
          program.value.ID
        )
        if ( previousSessions.value ) previousSession.value = previousSessions.value[0]
      }

      store.state.profile.ready = true
      return true
    }
    return true
  }

  return true

} )()
</script>
