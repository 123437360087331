import { ref } from 'vue'
import Http from '@/assets/ts/classes/Http'
import Favourite from '@/assets/ts/interfaces/Favourite'
// import isFavourite from '@/assets/ts/favourites/isFavourite';

/**
 * Composable functions for working with favourites.
 *
 * @returns Composable functions for working with favourites.
 */
const useFavourites = () => {

  const favourites = ref<any>( [] )
  const currentProgram = ref<any>( {} )

  const getFavourites = async ( memberId: number ) => Http.get( `/favourites/${memberId}` )

  const isFavourite = () => {
    return favourites.value.some( ( favorite: Favourite ) =>
      favorite.ID === currentProgram.value.id )
  }

  return { favourites, currentProgram, isFavourite, getFavourites }
}

export default useFavourites
