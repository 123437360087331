import { ref } from 'vue'

class Modal {
  constructor(
    public modalOpen = ref( false ),
    public openModal = ( state: boolean ) => {
      modalOpen.value = state
    } ) {}
  return() {
    this.modalOpen
    this.openModal
  }
}

export default Modal
