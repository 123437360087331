import { format } from 'date-fns'
import { ref } from 'vue'
import { useStore } from 'vuex'
import Http from '@/assets/ts/classes/Http'
import toast from '@/assets/ts/toast'

const userTimezone = ref<any>( null )
const timezones = ref<any>( [] )
const todayFormatted = ref<string|null>( null )
const monthFormatted = ref<string|null>( null )

const useUserTimezone = () => {

  const store = useStore()

  const checkUserTimezone = async () => {
    if ( store.state.profile.timezone ) {
      userTimezone.value = store.state.profile.timezone
    } else {
      store.state.profile.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      userTimezone.value = Intl.DateTimeFormat().resolvedOptions().timeZone
      userTimezone.value = await postUserTimezone( store.state.profile.ID, store.state.profile.timezone )
    }
    todayFormatted.value = format( new Date( new Date().toLocaleString( 'en-US', { timeZone: store.state.profile.timezone } ) ), 'yyyy-MM-dd' )
    monthFormatted.value = format( new Date( new Date().toLocaleString( 'en-US', { timeZone: store.state.profile.timezone } ) ), 'MMMM yyyy' )
  }

  /**
   * Gets a user's timezone.
   *
   * @param id The member ID.
   * @returns  The promise.
   */
  const getUserTimezone = async ( id: number ) => Http.get( `/member/${id}/timezone` )

  /**
   * Updates the user's timezone.
   *
   * @param e The change event.
   */
  const updateUserTimezone = async ( e: Event ) => {
    const target = e.target as HTMLSelectElement
    const timezone = target.value
    userTimezone.value = await postUserTimezone( store.state.profile.ID, timezone )
    store.state.profile.timezone = userTimezone.value
    toast.message = 'Settings saved successfully!'
    toast.isOpen = true
    setTimeout( () => {
      toast.isOpen = false
    }, toast.duration )
  }

  /**
   * Updates a user's timezone.
   *
   * @param id       The member ID.
   * @param timezone The timezone value as a string.
   * @returns        The promise.
   */
  const postUserTimezone = async ( id: number, timezone: string ) => Http.post( `/member/${id}/timezone`, { timezone } )

  return {
    userTimezone,
    timezones,
    postUserTimezone,
    updateUserTimezone,
    getUserTimezone,
    checkUserTimezone,
    todayFormatted,
    monthFormatted
  }
}

export default useUserTimezone
