import { createStore } from 'vuex'
import Store from '@/assets/ts/interfaces/Store'

const store = createStore( {
  state(): Store {
    return {
      exercises: [],
      profile: {
        received: false,
        subscription: {
          currency: '',
          customer: null,
          stripeId: null,
          active: null,
          planName: '',
        },
        location: {
          ip: null,
          geolocation: null,
          currency: {
            name: null,
            symbol: null,
          }
        },
        name: {
          firstName: '',
          lastName: '',
          nickname: '',
        },
        ID: 0,
        contactInfo: {
          emailAddress: '',
        },
        dateRegistered: '',
        profileImage: '',
        memberType: null,
        memberSince: null,
        settings: {
          dateFormat: '',
          macroViewMode: '',
          acceptedManualMacrosWarning: false,
          units: {
            weight: '',
            measurements: '',
          },
        },
        appSettings: {
          theme: null,
          sounds: null,
          notifications: null,
        },
        currentProgram: {
          thumb: null,
          name: null,
        },
        age: 0,
        dateOfBirth: '',
        height: {
          feet: 0,
          inches: 0,
          totalInches: 0,
          centimeters: 0,
        },
        gender: '',
        weight: 0,
        bodyFatPercentage: 0,
        activityLevel: '',
        mainGoal: '',
        experienceLevel: '',
        trainingFrequency: null,
        equipmentAvailable: null,
        dietPreference: null,
        timezone: '',
        macros: {
          calories: 0,
          protein: 0,
          fat: 0,
          carbs: 0,
        }
      },
      programs: null,
      user: '',
      lastSession: null,
      paymentMethods: null,
      loading: false,
      error: false,
      defaultPaymentMethod: null,
      session: {
        inProgress: false,
        id: null,
        sets: [],
      },
      program: {},
    }
  },
  getters: {
    programs( state: any ): any {
      return state.programs
    },
    user( state: any ): any {
      return state.user
    },
    paymentMethods( state: any ): any {
      return state.paymentMethods
    },
    program( state: any ) {
      return ( programId: any ): any => {
        return state.programs.find(
          ( program: any ) => program.id === Number( programId )
        )
      }
    },
  }
} )

export default store
