import {
  createRouter,
  createWebHistory
} from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import Http from '@/assets/ts/classes/Http'
import getMetaInfo from '@/assets/ts/getMetaInfo'
import Tabs from '@/components/base/Tabs.vue'
import isAuthorized from '@/router/auth'
import config from '@/router/config'

const Login = () => import ( '@/pages/PageLogin.vue' )
const Dashboard = () => import ( '@/pages/tabs/PageDashboard.vue' )
const Progress = () => import( '@/pages/tabs/PageProgress.vue' )
const ScaleWeight = () => import( '@/pages/PageScaleWeight.vue' )
const Programs = () => import( '@/pages/tabs/PagePrograms.vue' )
const Nutrition = () => import( '@/pages/tabs/PageNutrition.vue' )
const Lessons = () => import( '@/pages/tabs/PageLessons.vue' )

const ExerciseLibrary = () => import( '@/pages/PageExerciseLibrary.vue' )
const UpdateGoal = () => import( '@/pages/PageUpdateGoal.vue' )
const EditGoal = () => import( '@/pages/PageEditGoal.vue' )
const EditGoalWeight = () => import( '@/pages/PageEditGoalWeight.vue' )
const EditGoalRateOfLoss = () => import( '@/pages/PageEditGoalRateOfLoss.vue' )
const EditGoalRateOfGain = () => import( '@/pages/PageEditGoalRateOfGain.vue' )
const EditGoalDietPreference = () => import( '@/pages/PageEditGoalDietPreference.vue' )
const EditGoalMacros = () => import( '@/pages/PageEditGoalMacros.vue' )
const NewGoal = () => import( '@/pages/PageNewGoal.vue' )
const Goal = () => import( '@/pages/PageGoal.vue' )

const SingleExercise = () => import( '@/pages/single/PageSingleExercise.vue' )
const SingleProgram = () => import( '@/pages/single/PageSingleProgram.vue' )
const SingleRecipe = () => import( '@/pages/single/PageSingleRecipe.vue' )
const SingleSession = () => import( '@/pages/single/PageSingleSession.vue' )

const Settings = () => import( '@/pages/settings/PageSettings.vue' )
const AccountSettings = () => import( '@/pages/settings/PageAccountSettings.vue' )
const TrainingSettings = () => import( '@/pages/settings/PageTrainingSettings.vue' )

const PaymentTabs = () => import( '@/components/base/PaymentTabs.vue' )
const AddPaymentMethod = () => import( '@/pages/settings/PageAddPaymentMethod.vue' )
const YourPaymentDetails = () => import( '@/pages/settings/PageYourPaymentDetails.vue' )

const MyProfile = () => import( '@/pages/settings/PageMyProfile.vue' )
const MyMembership = () => import( '@/pages/settings/PageMyMembership.vue' )
const ChangePassword = () => import( '@/pages/settings/PageChangePassword.vue' )
const ConfirmPlan = () => import( '@/pages/settings/PageConfirmPlan.vue' )
const SubscriptionConfirm = () => import( '@/pages/settings/PageSubscriptionConfirm.vue' )

const WorkoutOverview = () => import( '@/pages/PageWorkoutOverview.vue' )
const SetOverview = () => import( '@/pages/PageSetOverview.vue' )
const SessionOverview = () => import( '@/pages/PageSessionOverview.vue' )
const AllPrograms = () => import( '@/pages/PageAllPrograms.vue' )

const NotFound = () => import( '@/pages/PageNotFound.vue' )

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          title: 'PLTNM – Dashboard',
          metaTags: [
            {
              name: 'description',
              content: 'Your dashboard.'
            },
            {
              name: 'og:description',
              content: 'Your dashboard.'
            }
          ],
        }
      },
      {
        path: 'progress',
        component: Progress,
        meta: {
          title: 'PLTNM – Progress',
          metaTags: [
            {
              name: 'description',
              content: 'Track your weight and measurements and view your progress.'
            },
            {
              name: 'og:description',
              content: 'Track your weight and measurements and view your progress.'
            }
          ],
        }
      },
      {
        path: 'programs',
        component: Programs,
        meta: {
          title: 'PLTNM – Programs',
          metaTags: [
            {
              name: 'description',
              content: 'View programs offered by PLTNM and choose one that fits you best.'
            },
            {
              name: 'og:description',
              content: 'View programs offered by PLTNM and choose one that fits you best.'
            }
          ],
        }
      },
      {
        path: 'nutrition',
        component: Nutrition,
        meta: {
          title: 'PLTNM – Nutrition',
          metaTags: [
            {
              name: 'description',
              content: 'Browse recipes to curate meals that best fit your macro goals.'
            },
            {
              name: 'og:description',
              content: 'Browse recipes to curate meals that best fit your macro goals.'
            }
          ],
        }
      },
      {
        path: 'lessons',
        component: Lessons,
        meta: {
          title: 'PLTNM – Lessons',
          metaTags: [
            {
              name: 'description',
              content: 'Browse lessons to learn more about nutrition, training and other fitness related content.'
            },
            {
              name: 'og:description',
              content: 'Browse lessons to learn more about nutrition, training and other fitness related content.'
            }
          ],
        }
      },
    ],
  },
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: '/settings/my-membership',
    component: MyMembership,
    meta: {
      title: 'PLTNM – My membership',
      metaTags: [
        {
          name: 'description',
          content: 'Manage your PLTNM subscription and membership.'
        },
        {
          name: 'og:description',
          content: 'Manage your PLTNM subscription and membership.'
        }
      ],
    }
  },
  {
    path: '/confirm-plan',
    component: ConfirmPlan,
    meta: {
      title: 'PLTNM – Confirm plan',
      metaTags: [
        {
          name: 'description',
          content: 'Confirm your new PLTNM plan.'
        },
        {
          name: 'og:description',
          content: 'Confirm your new PLTNM plan.'
        }
      ],
    }
  },
  {
    path: '/subscription-confirm',
    component: SubscriptionConfirm,
    meta: {
      title: 'PLTNM – Subscription confirmed!',
      metaTags: [
        {
          name: 'description',
          content: 'Welcome back! Your subscription has been confirmed.'
        },
        {
          name: 'og:description',
          content: 'Welcome back! Your subscription has been confirmed.'
        }
      ],
    }
  },
  {
    path: '/workout-overview',
    component: WorkoutOverview,
    meta: {
      title: 'PLTNM – Workout Overview',
      metaTags: [
        {
          name: 'description',
          content: 'Your workout overview for this session.'
        },
        {
          name: 'og:description',
          content: 'Your workout overview for this session.'
        }
      ],
    }
  },
  {
    path: '/set-overview',
    component: SetOverview,
    meta: {
      title: 'PLTNM – Set Overview',
      metaTags: [
        {
          name: 'description',
          content: 'Your current set.'
        },
        {
          name: 'og:description',
          content: 'Your current set.'
        }
      ],
    }
  },
  {
    path: '/session-overview',
    component: SessionOverview,
    meta: {
      title: 'PLTNM – Session Overview',
      metaTags: [
        {
          name: 'description',
          content: 'Your completed session overview.'
        },
        {
          name: 'og:description',
          content: 'Your completed session overview.'
        }
      ],
    }
  },
  {
    path: '/exercise-library',
    component: ExerciseLibrary,
    meta: {
      title: 'PLTNM – Exercise Library',
      metaTags: [
        {
          name: 'description',
          content: 'View all exercises in the PLTNM exercise library.'
        },
        {
          name: 'og:description',
          content: 'View all exercises in the PLTNM exercise library.'
        }
      ],
    }
  },
  {
    path: '/account-settings',
    component: AccountSettings,
    meta: {
      title: 'PLTNM – Account settings',
      metaTags: [
        {
          name: 'description',
          content: 'Manage settings related to your account.'
        },
        {
          name: 'og:description',
          content: 'Manage settings related to your account.'
        }
      ],
    }
  },
  {
    path: '/account-settings/change-password',
    component: ChangePassword,
    meta: {
      title: 'PLTNM – Change password',
      metaTags: [
        {
          name: 'description',
          content: 'Change your current PLTNM password.'
        },
        {
          name: 'og:description',
          content: 'Change your current PLTNM password.'
        }
      ],
    }
  },
  {
    path: '/training-settings',
    component: TrainingSettings,
    meta: {
      title: 'PLTNM – Training settings',
      metaTags: [
        {
          name: 'description',
          content: 'Manage settings related to your training.'
        },
        {
          name: 'og:description',
          content: 'Manage settings related to your training.'
        }
      ],
    }
  },
  {
    path: '/my-profile',
    component: MyProfile,
  },
  {
    path: '/programs/:id',
    props: true,
    component: SingleProgram,
  },
  {
    path: '/nutrition/update-goal',
    component: UpdateGoal,
  },
  {
    path: '/new-goal',
    component: NewGoal,
  },
  {
    path: '/edit-goal',
    component: EditGoal,
  },
  {
    path: '/edit-goal/goal-weight',
    component: EditGoalWeight,
  },
  {
    path: '/edit-goal/rate-of-loss',
    component: EditGoalRateOfLoss,
  },
  {
    path: '/edit-goal/rate-of-gain',
    component: EditGoalRateOfGain,
  },
  {
    path: '/edit-goal/diet-preference',
    component: EditGoalDietPreference,
  },
  {
    path: '/edit-goal/macros',
    component: EditGoalMacros,
  },
  {
    path: '/goal',
    component: Goal,
  },
  {
    path: '/exercises/:id',
    component: SingleExercise,
  },
  {
    path: '/recipes/:id',
    component: SingleRecipe,
  },
  {
    path: '/sessions/:id',
    component: SingleSession,
  },
  {
    path: '/payment-settings',
    redirect: '/payment-settings/your-payment-details',
  },
  {
    path: '/payment-settings',
    component: PaymentTabs,
    children: [
      {
        path: '/payment-settings/your-payment-details',
        component: YourPaymentDetails
      },
      {
        path: '/payment-settings/add-payment-method',
        component: AddPaymentMethod
      },
    ],
  },
  {
    path: '/scale-weight',
    component: ScaleWeight
  },
  {
    path: '/programs/all',
    component: AllPrograms
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  },
]

const router = createRouter( {
  history: createWebHistory( import.meta.env.BASE_URL ),
  routes
} )

router.beforeEach( async ( to, from, next ) => {
  if ( window.location.pathname !== '/login' && ! localStorage.getItem( 'pltnmToken' ) ) window.location.href = '/login'
  getMetaInfo( to, from )
  next()
} )

export default router
