import { RouteLocationNormalized } from 'vue-router'

const getMetaInfo = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
): boolean|HTMLMetaElement|void => {

  const nearestWithTitle = to.matched.slice().reverse().find( r => r.meta && r.meta.title )

  // Find the nearest route element with meta tags.
  const nearestWithMeta: any = to.matched.slice().reverse().find( r => r.meta && r.meta.metaTags )
  const previousNearestWithMeta = from.matched.slice().reverse().find( r => r.meta && r.meta.metaTags )

  // If a route with a title was found, set the document (page) title to that value.
  if( nearestWithTitle ) {
    document.title = nearestWithTitle.meta.title as string
  } else if( previousNearestWithMeta ) {
    document.title = previousNearestWithMeta.meta.title as string
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from( document.querySelectorAll( '[data-vue-router-controlled]' ) ).map( el => {
    if ( el.parentNode !== null ) el.parentNode.removeChild( el )
  } )

  // Skip rendering meta tags if there are none.
  if ( ! nearestWithMeta ) return false

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map( ( tagDef: any ) => {
    const tag = document.createElement( 'meta' )

    Object.keys( tagDef ).forEach( key => {
      tag.setAttribute( key, tagDef[key] )
    } )

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute( 'data-vue-router-controlled', '' )

    return tag
  } )
    // Add the meta tags to the document head.
    .forEach( ( tag: any ) => document.head.appendChild( tag ) )
}

export default getMetaInfo
