import { ref } from 'vue'
import { Store } from 'vuex'
import Http from '@/assets/ts/classes/Http'
import type Program from '@/assets/ts/interfaces/Program'
import type Session from '@/assets/ts/interfaces/Session'
import type Workout from '@/assets/ts/interfaces/Workout'
import useCurrentSet from '@/assets/ts/sessions/useCurrentSet'
import useTodaysWorkout from '@/assets/ts/sessions/useTodaysWorkout'
import useStoreProgram from '@/store/useStoreProgram'

const { program } = useStoreProgram()
const { session } = useCurrentSet()
const { getMoveToToday } = useTodaysWorkout()

/**
 * Dynamic object to handle contents for the "Week in Review" modal.
 */
const workoutsThisWeek = ref<any>( {
  completedWorkouts: [],
  remainingWorkouts: []
} )

/**
 * Number used for the "start session" countdown.
 */
const countdown = ref( 10 )

/**
 * Boolean to reference if the countdown has begun.
 */
const countdownStarted = ref( false )
const startSessionDisabled = ref( false )

const useWorkoutWeek = () => {

  /**
   * Get the first Workout in the specified Week.
   *
   * @param workoutWeek The week number to check against.
   */
  const getFirstWorkoutInWeek = ( workoutWeek: number ) =>
    program.value.workouts.find( ( workout: any ) =>
      workout.weekNumbers.includes( workoutWeek ) )

  /**
   * Setup the Week In Review after configurations are modified.
   *
   * @param store The global store.
   * @param todaysWorkout The current state of today's workout.
   */
  const setupWeekInReview = async ( store: Store<any>, todaysWorkout: any ) => {

    // Get the updated workout week from the server.
    workoutsThisWeek.value.workoutWeek = await getCompletedSessions( store.state.profile.ID )

    // Update completed workouts.
    workoutsThisWeek.value.completedWorkouts = workoutsThisWeek.value.workoutWeek.sessions.completedSessions

    // Update workout week.
    store.state.profile.currentProgram.workoutWeek = workoutsThisWeek.value.workoutWeek.workoutWeekNumber

    // Update remaining workouts.
    workoutsThisWeek.value.remainingWorkouts = await getWorkoutsThisWeek(
      workoutsThisWeek.value.completedWorkouts,
      store.state.profile.currentProgram.workoutWeek,
      program.value,
      todaysWorkout
    )

    countdownStarted.value = false
    countdown.value = 10
    startSessionDisabled.value = false
    session.value.inProgress = false
    store.state.session = {
      inProgress: false,
      complete: false,
      id: null,
      sets: [],
    }
    session.value.sets = []

  }

  /**
   * POST request to reset the current week by resetting all
   * Sessions for the Member that have the current Week Number.
   *
   * @param id The member ID.
   */
  const resetWeek = async ( id: number ) => Http.post( `/member/${id}/workout-week`, { reset: true } )

  /**
   * POST request to handle switching a phase in a Program.
   *
   * @param id The member ID.
   * @param phase An object containing the new week number and if it's a previous phase.
   */
  const switchPhase = async ( id: number, phase: Record<string, unknown> ) =>
    Http.post( `/member/${id}/workout-week`, { switchPhase: true, ...phase } )

  /**
   * POST request to handle skipping a week, which increments
   * the week on the server (ex: Week 1 > Week 2).
   *
   * @param id The member ID.
   */
  const skipWeek = async ( id: number ) =>
    Http.post( `/member/${id}/workout-week`, { skip: true } )

  const getCompletedSessions = async ( id: number ) => Http.get( `/member/${id}/workout-week` )

  /**
   * Organize the workouts for the week for the "Week in Review" modal.
   *
   * @param completed The completed workouts for the Week.
   * @param workoutWeek The Workout Week Number.
   * @param program The program.
   * @param todaysWorkout Today's workout.
   * @param memberId The member's ID.
   */
  const getWorkoutsThisWeek = async (
    completed: Session[],
    workoutWeek: number,
    program: Program,
    todaysWorkout: any = null,
    memberId = 0
  ) => {

    /**
     * Remaining workouts are initially equal to workouts in the program that
     * are in the current week and have not yet been completed.
     */
    const remainingWorkouts = program.workouts.filter( ( workout: Workout ) =>
      workout.weekNumbers.includes( workoutWeek )
        && ! completed.find( ( session: Session ) => session.workout.ID === workout.ID ) )

    /**
     * If we don't have today's workout, check if one is saved in the database,
     * otherwise set it to the first remaining.
     */
    if ( ! todaysWorkout.value.ID ) {

      /**
       * Check if we have a workout saved for today.
       */
      const response = await getMoveToToday( memberId )

      /**
       * Check if our saved workout is in the remaining workouts.
       */
      const workoutFound = remainingWorkouts.find( ( workout: Workout ) => workout.ID === response.moveToToday )

      /**
       * Update today's workout if we do, otherwise use the first in the remaining.
       */
      todaysWorkout.value = response.moveToToday && workoutFound ? workoutFound : remainingWorkouts[0]

    }

    /**
     * Set the remaining workouts to the workouts that aren't today.
     */
    return remainingWorkouts.filter( ( workout: Workout ) => workout.ID !== todaysWorkout.value.ID )

  }

  return {
    countdownStarted,
    countdown,
    startSessionDisabled,
    workoutsThisWeek,
    resetWeek,
    skipWeek,
    switchPhase,
    getWorkoutsThisWeek,
    getCompletedSessions,
    setupWeekInReview,
    getFirstWorkoutInWeek
  }

}

export default useWorkoutWeek
