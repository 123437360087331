import {
  computed,
  ref
} from 'vue'
import Http from '@/assets/ts/classes/Http'
import type Set from '@/assets/ts/interfaces/Set'
import useTodaysWorkout from '@/assets/ts/sessions/useTodaysWorkout'

const { todaysWorkout } = useTodaysWorkout()

const session = ref( {
  inProgress: false,
  sets: <any[]> [],
  ID: 0
} )
const totalSets = computed( () => {
  let total = 0
  todaysWorkout.value.setGroups.forEach( ( setGroup: any ) => {
    total += setGroup.sets * setGroup.exercises.length
  } )
  return total
} )
const currentSetIndex = ref( 0 )
const currentExerciseIndex = ref( 0 )
const currentSetTotalIndex = ref( 1 )
// const percentComplete = ref( 0.0 )
const currentSet = ref( 0 )
const sessionComplete = ref( false )
const restTimerActive = ref( false )
const previousSet = ref<any>( null )

const useCurrentSet = () => {

  const updateSet = async ( set: Set, sessionId: number ) =>
    await Http.patch( `/member/session/${sessionId}`, { set, sessionId, update: true } )

  const saveSet = async ( set: any, sessionId: number ) => Http.patch( `/member/session/${sessionId}`, { set, sessionId } )

  const saveSetAsync = async ( set: any, sessionId: number ) => Http.patch( `/member/session/${sessionId}`, { set, sessionId } )

  /**
   * Find the set in a setGroup that matches the updated set.
   */
  const getMatchingSet = ( exercise: any, setUpdated: any ) => exercise.sets.find( ( set : any ) =>
    set.setNumber === setUpdated[0].rowData.set_number
    && set.setGroup === setUpdated[0].rowData.set_group
    && set.exerciseNumber === setUpdated[0].rowData.exercise_number
  )

  /**
   * Update the matching set in the UI after it's updated.
   */
  const updateMatchingSet = ( setUpdated: any, session: any ) => {

    if ( session.value ) {

      console.log( 'session.value', session.value )
      let matchingSet = null

      if ( session.value.exercises ) {
        const exerciseGroup = session.value.exercises.find(
          ( exercise: any ) => getMatchingSet( exercise, setUpdated )
        )

        matchingSet = getMatchingSet( exerciseGroup, setUpdated )
      } else {
        matchingSet = getMatchingSet( session.value, setUpdated )
      }

      matchingSet.weight = setUpdated[0].rowData.weight
      matchingSet.reps = setUpdated[0].rowData.reps
      matchingSet.rpe = setUpdated[0].rowData.difficulty
      matchingSet.setNote = setUpdated[0].rowData.set_note
      console.log( 'matchingSet', matchingSet, Boolean( setUpdated[0].rowData.skipped ) )
      matchingSet.skipped = Boolean( setUpdated[0].rowData.skipped )

    }
  }

  return {
    currentSetIndex,
    currentExerciseIndex,
    currentSetTotalIndex,
    // percentComplete,
    currentSet,
    sessionComplete,
    session,
    saveSet,
    saveSetAsync,
    restTimerActive,
    previousSet,
    totalSets,
    updateSet,
    getMatchingSet,
    updateMatchingSet
  }
}

export default useCurrentSet
