import env from '@/env'
import ENV from '@/env/ENV'

const config = {
  rootURL: ENV[env].rootURL,
  apiBase: `${ENV[env].rootURL}/wp-json/api/v1`,
  authURI: `${ENV[env].rootURL}/oauth/token`,
  clientID: ENV[env].clientID,
  responseType: 'token',
  tokenName: 'pltnmToken',
  stripe: {
    apiKeys: {
      live: 'pk_live_lmssXh8U1mVbUC0x6EDE0DYm'
    }
  }
}

const authRedirect = `${config.authURI}?client_id=${config.clientID}&response_type=${config.responseType}`

export default config
export { authRedirect }
