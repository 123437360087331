import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { IonicVue } from '@ionic/vue'
import { createPinia } from 'pinia'
import VCalendar from 'v-calendar'
import { createApp } from 'vue'
import App from '@/App.vue'
import BaseLayout from '@/components/base/BaseLayout.vue'
import setupSentry from '@/plugins/sentry'
import router from '@/router'
import store from '@/store'
import '@/assets/css/index.css'
import 'v-calendar/dist/style.css'
import '@ionic/vue/css/core.css'
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'
import '@/assets/css/theme/aspect-ratio.css'
import '@/assets/css/theme/buttons.css'
import '@/assets/css/theme/links.css'
import '@/assets/css/theme/variables.css'
import '@/assets/css/theme/transitions.css'
import '@/assets/css/components/select.css'
import '@/assets/css/components/checkbox.css'
import '@/assets/css/components/radio.css'
import '@/assets/css/components/range.css'

const app = createApp( App )
  .use( IonicVue )
  .use( router )
  .use( store )
  .use( createPinia() )
  .use( VCalendar, {} )

setupSentry( app )

app.component( 'BaseLayout', BaseLayout )

router.isReady().then( () => {
  app.mount( '#app' )
  defineCustomElements( window )
} )
