<template>
  <ion-page>
    <ion-header
      :translucent="floatingHeader ? false : true"
      class="ion-no-border"
      :class="floatingHeader ? 'toolbar-overlay absolute' : ''"
    >
      <ion-toolbar>
        <ion-buttons slot="start">
          <slot name="actions-start" />
          <BackButtonFloating
            v-if="! hideBackButton && floatingHeader"
            :default-href="pageDefaultBackLink"
          />
          <ion-back-button
            v-if="! hideBackButton && ! floatingHeader"
            :default-href="pageDefaultBackLink"
          />
        </ion-buttons>
        <!-- eslint-disable vue/no-v-text-v-html-on-component -->
        <ion-title
          v-if="! floatingHeader"
          v-html="pageTitle"
        />
        <ion-buttons slot="end">
          <slot name="actions-end" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content
      :scroll-events="scrollEvents"
      @ionScroll="logScrolling"
    >
      <slot />
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/vue'
import useSessions from '@/assets/ts/useSessions'
import BackButtonFloating from '@/components/BackButtonFloating.vue'

interface Props {
  /**
   * The title of the page.
   */
  pageTitle?: string;
  /**
   * The default back link for the back button.
   */
  pageDefaultBackLink?: string;
  /**
   * Whether to hide the back button.
   */
  hideBackButton?: boolean;
  /**
   * Whether to make the header floating.
   *
   * @default false
   */
  floatingHeader?: boolean;
  /**
   * Whether to enable scroll events.
   *
   * @link https://ionicframework.com/docs/api/content#scrollevents
   * @default false
   */
  scrollEvents?: boolean;
}

withDefaults( defineProps<Props>(), {
  floatingHeader: false,
  scrollEvents: false
} )

const { logScrolling } = useSessions()

</script>

<style scoped>
.toolbar-overlay ion-toolbar {
  --background: transparent !important;
}

.toolbar-overlay .header-background {
  display: none;
}
</style>