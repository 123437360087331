import { defineStore } from 'pinia'
import Favourite from '@/assets/ts/interfaces/Favourite'

interface FavouritesStore {
  favourites: Favourite[];
  filter: 'programs';
  nextId: number;
}

const useStoreFavourites = defineStore( 'favourites', {
  state: (): FavouritesStore => ( {
    favourites: [],
    filter: 'programs',
    nextId: 0,
  } ),
  getters: {
    favouritePrograms( state ) {
      return state.favourites.filter( favourite => favourite.type === 'program' )
    },
  },
  actions: {
    addFavourite( favourite: Favourite ) {
      this.favourites.push( favourite )
    },
  },
} )

export default useStoreFavourites