import { format } from 'date-fns'
import {
  computed,
  ref
} from 'vue'
import { Store } from 'vuex'
import Http from '@/assets/ts/classes/Http'
import Workout from '@/assets/ts/interfaces/Workout'
import useStoreProgram from '@/store/useStoreProgram'

const { program } = useStoreProgram()

const todaysWorkout = ref<Workout>(
  {
    ID: 0,
    additionalInfo: 'string',
    day: 0,
    groupTitle: '',
    setGroups: [],
    title: '',
    type: '',
    weekNumbers: [],
    savedAlternates: []
  }
)

const hasUsedAlternates = computed( () =>
  todaysWorkout.value.setGroups.find( ( setGroup: any ) =>
    setGroup.exercises.find( ( exercise: any ) =>
      exercise.chosenExercise !== undefined && exercise.chosenExercise !== exercise.ID
    )
  )
)

const alternateExercises = computed( () => {
  const alternates: any = []
  todaysWorkout.value.setGroups.forEach( ( setGroup: any ) => {
    setGroup.exercises.forEach( ( exercise: any ) => {
      if ( exercise.chosenExercise !== undefined && exercise.chosenExercise !== exercise.ID ) {
        alternates.push( exercise.chosenExercise )
      }
    } )
  } )
  return alternates
} )

const useTodaysWorkout = ( store?: Store<any> ) => {

  /**
   * Handle updating workout for "Move to today".
   */
  const postMoveToToday = async ( memberId: number, moveToToday: number ) => await Http.post( `/member/${memberId}/move-to-today`, { moveToToday } )

  /**
   * Check if the member has a value saved for "Move to today" in their settings.
   * This will return 0 if the field is empty or reset.
   */
  const getMoveToToday = async ( memberId: number ) => await Http.get( `/member/${memberId}/move-to-today` )

  /**
   * Update saved alternates if they exist.
   */
  const handleSetAlternates = () => {

    /**
     * Check if this workout has any saved alternates.
     */
    if ( todaysWorkout.value.savedAlternates && todaysWorkout.value.savedAlternates.length ) {

      /**
       * Loop through each saved alternate.
       */
      todaysWorkout.value.savedAlternates.forEach( ( savedAlternate : any ) => {

        /**
         * Loop through the set groups.
         */
        todaysWorkout.value.setGroups.forEach( ( setGroup: any, setGroupIndex: number ) => {

          /**
           * Loop through each exercise in each set group.
           */
          setGroup.exercises.forEach( ( exercise: any ) => {

            /**
             * If the exercise has alternates, loop through those.
             */
            if ( exercise.alternateExercises ) {

              /**
               * Find the alternate exercise that matches the one saved.
               */
              const matchingExercise = exercise.alternateExercises.find(
                ( alternateExercise : any ) => alternateExercise.ID === savedAlternate.alternateId )

              /**
               * Confirm that the set group matches the saved set group.
               */
              const setGroupMatches = setGroupIndex + 1 === savedAlternate.setGroup

              /**
               * If we find one that matches, set it as the chosenExercise.
               */
              if ( matchingExercise && setGroupMatches ) exercise.chosenExercise = matchingExercise.ID

            }
          } )
        } )
      } )
    }
  }

  const getTodaysWorkout = ( store: Store<any> ) => {
    const todayFormatted = format( new Date( new Date().toLocaleString( 'en-US', { timeZone: store.state.profile.timezone } ) ), 'yyyy-MM-dd' )
    if ( todayFormatted === store.state.profile.currentProgram.startDate ) {
      todaysWorkout.value = program.value.workouts[0]
    } else {
      todaysWorkout.value =
        {
          ID: 0,
          additionalInfo: '',
          day: 0,
          groupTitle: '',
          setGroups: [],
          title: '',
          type: '',
          weekNumbers: [],
          savedAlternates: []
        }
    }
  }

  return {
    todaysWorkout,
    getTodaysWorkout,
    handleSetAlternates,
    alternateExercises,
    postMoveToToday,
    getMoveToToday
  }

}

export default useTodaysWorkout
