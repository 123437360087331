/**
 * Check an array of values to assure they all exist.
 *
 * @param {array} requirements An array of values to check for.
 */
const hasRequirements = ( requirements: any ) => {
  // @ts-ignore
  requirements.forEach( ( requirement: any, index: any ) => {
    if ( requirement === false ) {
      console.warn( `Missing requirement: ${index + 1}.` )
      return false
    }
  } )
  return true
}

export default hasRequirements
