import { isEqual } from 'lodash'
import {
  computed,
  ref
} from 'vue'
import Http from '@/assets/ts/classes/Http'
import type Session from '@/assets/ts/interfaces/Session'
import toast from '@/assets/ts/toast'
import Modal from '@/assets/ts/useModal'
import usePreviousSession from '@/assets/ts/usePreviousSession'

const { previousSession } = usePreviousSession()

const modalOpen = ref( false )
const openModal = ( state: boolean ) => modalOpen.value = state

/**
 * The current session being queried in a single session view.
 * Null by default and nullified when the view is unmounted.
 */
const session = ref<Session|null>( null )

const modalSingleSessionValue = new Modal()
const modalSheetSingleSessionValue = new Modal()

/**
 * The computed length of the session.
 */
const sessionLength = computed( () => {
  let length = ''
  if ( session.value ) {
    length += session.value.lengthUnits.hours > 0 ? `${session.value.lengthUnits.hours}h ` : ''
    length += session.value.lengthUnits.minutes > 0 ? `${session.value.lengthUnits.minutes}m ` : ''
    length += session.value.lengthUnits.seconds > 0 ? `${session.value.lengthUnits.seconds}s` : ''
  }
  return length
} )

/**
 * Ref used to hold the scrollTop value.
 */
const scrollTop = ref( 0 )

/**
 * Computed value to determine when to show the second save button.
 */
const showSaveButton = computed( () => scrollTop.value > 120 )

/**
 * Assign the current scroll top value to the ref.
 * @param event The ion-content scroll event.
 */
const logScrolling = ( event: CustomEvent ) => scrollTop.value = event.detail.scrollTop

/**
 * Array containing sessions that have already been fetched.
 */
const cachedSessions = ref<Session[]>( [] )

/**
 * The session we are currently viewing.
 */
const selectedSession = ref<Session|undefined>( undefined )

/**
 * Ref to represent total sessions completed by a member.
 */
const totalSessions = ref( 0 )

/**
 * Check if a session has already been cached.
 *
 * @param sessionId The session ID to check against.
 */
const sessionIsCached = ( sessionId: number ) =>
  cachedSessions.value.find( ( session: Session ) => session.ID === sessionId )

/**
 * Boolean determinator for showing the exercise swaps toggle.
 */
const showExerciseSwaps = computed( () =>
  ! isEqual( previousSessionAlternates.value, sessionAlternateExercises.value ) )

/**
 * Determine if the queried session is using alternate exercises.
 */
const sessionHasAlternateExercises = computed( () => {
  const alternates: any = []
  if ( session.value ) {
    session.value.exercises.forEach( ( exercise: any ) => {
      exercise.sets.forEach( ( set: any ) => {
        if ( set.exercise.alternate === true && ! alternates.find( ( alternate: any ) => alternate.alternateId === set.exercise.ID ) ) {
          alternates.push( {
            programId: session.value?.program.ID,
            workoutId: session.value?.workout.ID,
            alternateId: set.exercise.ID,
            setGroup: set.setGroup,
            exerciseNumber: set.exerciseNumber,
          } )
        } else if ( set.exercise.switchedToMain && ! alternates.find( ( alternate: any ) => alternate.alternateId === set.exercise.ID ) ) {
          alternates.push( {
            programId: session.value?.program.ID,
            workoutId: session.value?.workout.ID,
            alternateId: set.exercise.ID,
            setGroup: set.setGroup,
            exerciseNumber: set.exerciseNumber,
            delete: true
          } )
        }
      } )
    } )
  }
  return alternates
} )

/**
 * Get the alternates from the previous session.
 */
const previousSessionAlternates = computed( () => {
  const alternates: any = []
  if ( previousSession.value ) {
    previousSession.value.sets.forEach( ( set: any ) => {
      if ( set.exercise.alternate === true && ! alternates.find( ( alternate: any ) => alternate === set.exercise.ID ) ) {
        alternates.push( set.exercise.ID )
      } else if ( set.exercise.switchedToMain && ! alternates.find( ( alternate: any ) => alternate === set.exercise.ID ) ) {
        alternates.push( set.exercise.ID )
      }
    } )
  }
  return alternates
} )

/**
 * Determine if the queried session is using alternate exercises.
 */
const sessionAlternateExercises = computed( () => {
  const alternates: any = []
  if ( session.value ) {
    session.value.exercises.forEach( ( exercise: any ) => {
      exercise.sets.forEach( ( set: any ) => {
        if ( set.exercise.alternate === true && ! alternates.find( ( alternate: any ) => alternate === set.exercise.ID ) ) {
          alternates.push( set.exercise.ID )
        } else if ( set.exercise.switchedToMain && ! alternates.find( ( alternate: any ) => alternate === set.exercise.ID ) ) {
          alternates.push( set.exercise.ID )
        }
      } )
    } )
  }
  return alternates
} )

const sessionRequest = ref( false )

/**
 * Composable for handling sessions.
 */
const useSessions = () => {

  /**
   * Handle getting and caching sessions.
   *
   * @param sessionId The session ID to query against.
   */
  const handleGetSession = async ( sessionId: number ) => {
    session.value = sessionIsCached( sessionId ) ?? await getSession( sessionId )
    if ( ! sessionIsCached( sessionId ) && session.value ) cachedSessions.value.push( session.value )
  }

  /**
   * Get the total number of sessions for a member.
   *
   * @param memberId The member ID.
   */
  const getTotalSessions = async( memberId: number ) => Http.get( `/member/${memberId}/total-sessions` )

  /**
   * Get sessions with optional parameters.
   */
  const getSessions = async ( params: object ) => Http.get( '/member/sessions', { params } )

  /**
   * GET request for retrieving a session by ID.
   *
   * @param sessionId The session ID to retrieve.
   */
  const getSession = async ( sessionId: number ) => Http.get( `/member/session/${sessionId}` )

  /**
   * DELETE request for deleting an existing session.
   *
   * @param sessionId The session ID.
   * @param userId The user ID.
   */
  const deleteSession = async ( sessionId: number ) => {
    return Http.delete( `/session/${sessionId}` )
      .then( () => {
        toast.message = 'Session ended successfully'
        toast.isOpen = true
        setTimeout( () => {
          toast.isOpen = false
        }, 2000 )
      } )
      .catch( error => console.log( error ) )
  }

  return {
    deleteSession,
    modalOpen,
    openModal,
    getSession,
    handleGetSession,
    session,
    sessionLength,
    sessionHasAlternateExercises,
    sessionAlternateExercises,
    previousSessionAlternates,
    showExerciseSwaps,
    scrollTop,
    showSaveButton,
    logScrolling,
    totalSessions,
    getTotalSessions,
    getSessions,
    selectedSession,
    modalSingleSessionValue,
    modalSheetSingleSessionValue,
    sessionRequest
  }

}

export default useSessions
